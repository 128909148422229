<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('form.name') }}</label>
            <vs-input border v-model="typeform.name" placeholder="Lorem" class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.name.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>UUD</label>
            <vs-input border v-model="typeform.uuid" class="shadow-lg" />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('currencies.abbreviation') }}</label>
            <vs-input border v-model="typeform.short" placeholder="Ej: 'USD'" class="shadow-lg"
              :class="{ 'is-invalid': typesubmit && $v.typeform.short.$error }" />
            <div v-if="typesubmit && $v.typeform.short.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.short.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('currencies.form.symbol') }}</label>
            <vs-input border v-model="typeform.symbol" placeholder="Ej: '$'" class="shadow-lg"
              :class="{ 'is-invalid': typesubmit && $v.typeform.symbol.$error }" />
            <div v-if="typesubmit && $v.typeform.symbol.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.symbol.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('currencies.form.exchangeValue') }}</label>
            <vs-input border v-model="typeform.usdExchange" class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.usdExchange.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.usdExchange.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.usdExchange.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" v-if="typeform._id">
          <div class="mb-3">
            <vs-checkbox :value="typeform.status" ref="status">
              {{ $t('currencies.form.status') }}
            </vs-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4 mt-3">
        <vs-checkbox v-model="typeform.exchangeApi">
          {{ $t('currencies.form.automaticUpdate') }}
        </vs-checkbox>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">{{ $t('form.save') }}</vs-button>
          <vs-button success type="button" @click="$emit('closeModa')">{{ $t('form.abort') }}</vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';
import Swal from "sweetalert2";

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },

    apis: {
      type: Array,
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      apiSelected: "",
      status: null
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      short: {
        required,
      },
      symbol: {
        required,
      },
      usdExchange: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'currencies/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors'
    })
  },
  methods: {
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log("error");

      if (this.typeform._id) {
        const status = this.$refs.status.$el.querySelector('[type=checkbox]').checked;
        const dataToSave = { ...this.typeform, status };
        const { _id, uuid, ...dataToUpdate } = dataToSave;
        await this.updateCurrency({ _id, uuid, dataToUpdate })

        if (!this.success) {
          this.checkErrors()
          return Swal.fire({
            position: "center",
            icon: "error",
            title: 'Error actualizando la currency',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Moneda guardada con exito",
          showConfirmButton: false,
          timer: 1500,
        });

        return this.$emit("closeModa");
      } else {
        await this.addCurrency({ doc: { ...this.typeform } })

        if (this.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Moneda registrada con exito",
            showConfirmButton: false,
            timer: 1500,
          });

          this.checkErrors()
          return this.$emit("closeModa");
        }

        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error registrando la moneda",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`
          this.openWinNotification(null, 'warning', 'ERROR', message)
        })
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    ...mapActions({
      addCurrency: 'currencies/addCurrency',
      updateCurrency: 'currencies/updateCurrency'
    })
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :moda_title="$t('currencies.form.title')" ref="pageForm" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" :apis="apis" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <!-- <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    > -->
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                        }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>

              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <!-- <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    {{$t('filter.search')}}:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                    <vs-input v-model="filter" placeholder="" />
                  </label>
                </div> -->
                <div class="d-flex align-items-center justify-content-end">
                  <span class="me-2">{{ $t('filter.search') }}: </span>
                  <vs-input primary v-model="filter" placeholder="Ej: Dolar" class="shadow-lg" />
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" style="overflow-x: scroll" v-if="tableData.length">
                <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"
                  :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                  :filter-included-fields="filterOn" @filtered="onFiltered">
                  <template #cell(exchangeApi)="data">
                    <div v-if="data.item.exchangeApi">&check;</div>
                    <div v-else>No</div>
                  </template>
                  <template #cell(updatedAt)="data">
                    {{ moment.utc(data.item.updatedAt).format("DD-MM-YYYY") }}
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <div class="action-btn-container action-table">
                      <b-dropdown variant="link" no-caret class="dropdown-items">
                        <template #button-content>
                          <i class="fa fa-fw fa-bars font-size-16" />
                        </template>
                        <b-dropdown-item :to="{ name: 'currencie-show', params: { id: data.item._id } }">
                          <i class="uil-file-alt m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t("actions.subItems.details") }}</span>
                        </b-dropdown-item>

                        <div v-if="isAdmin">
                          <b-dropdown-item @click="edit(data.item)">
                            <i class="uil-edit m-2 font-size-18" />
                            <span class="align-middle ml-5">{{ $t("actions.subItems.edit") }}</span>
                          </b-dropdown-item>
                        </div>

                        <div v-if="isAdmin">
                          <b-dropdown-item @click="remove(data.item, data.item.name)">
                            <i class="mdi mdi-delete-outline m-2 font-size-18" />
                            <span class="align-middle ml-5">{{ $t("actions.subItems.delete") }}</span>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form.vue";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Currencies Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, PageForm },
  data() {
    return {
      vueSaxSelect: "",
      tableData: [],
      title: "Monedas",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Monedas",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [],

      typeform: {
        name: "",
        short: "",
        symbol: "",
        usdExchange: "",
      },

      apis: [],
      userRole: "",
      loading: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      user: 'user/getUserData',
      getCurrencies: "currencies/getCurrencies",
      mapApis: "exchangeApis/getApis",
      currencySuccess: 'currencies/getSuccess',
      getCurrenciesFields: 'currencies/getCurrenciesFields',
      isAdmin: 'user/isAdmin',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors'
    })
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getUserRole();

    this.getApis();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    getUserRole() {
      const role = this.$store.getters["user/getRole"]
      this.userRole = role;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      this.loading = true
      try {
        await this.fetchCurrencies({ role: this.user.role.name, user: this.user });
        this.fields = this.getCurrenciesFields;
        this.tableData = [...this.getCurrencies];
        this.loading = false
      } catch (error) {
        console.log('ERROR GET DATA', error);
        this.loading = false

      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;

      this.typeform = {
        name: "",
        short: "",
        symbol: "",
        usdExchange: "",
      };
      this.getData();
    },
    edit(item) {
      this.typeform = item;
      this.$refs.pageForm.modal = true;
    },
    async remove(currency, name) {
      if (!currency) return;

      const { isConfirmed } = await Swal.fire({
        title: `¿Desea eliminar "${name}"?`,
        showDenyButton: true,
        icon: "info",
        confirmButtonText: "Sí",
        denyButtonText: "No",
      })

      if (!isConfirmed) return;

      await this.deleteCurrency(currency);

      if (!this.currencySuccess) {
        this.checkErrors()
        return Swal.fire({
          position: "center",
          icon: "error",
          title: 'Error eliminando la currency',
          showConfirmButton: false,
          timer: 1500,
        });
      }

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Moneda eliminada con exito",
        showConfirmButton: false,
        timer: 1500,
      });
      this.getData();
      this.checkErrors()
    },

    async getApis() {
      try {
        await this.fetchApis();
        this.apis = this.mapApis;
      } catch (err) {
        console.log(err);
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`
          this.openWinNotification(null, 'warning', 'ERROR', message)
        })
      }
    },
    ...mapActions({
      fetchCurrencies: "currencies/fetchCurrencies",
      fetchApis: "exchangeApis/fetchExchangeApis",
      deleteCurrency: 'currencies/deleteCurrency',
    })
  },
  middleware: "authentication",
};
</script>

<style scoped>
.space-nowrap {
  white-space: nowrap !important;
}

.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}
</style>
